<template>
    <vFormPage>
        <div slot="header" class="header">
            <div class="close" @click.prevent="handleClose">×</div>
            <div class="title">ACCOUNT SETTING</div>
        </div>
        <div class="profile-body">
            <div class="form-title-wrapper">
                <img :src="ME_ACTIVE_SRC" alt="">
            </div>
            <div class="item-wrapper">
                <vFormItemH>
                    <div slot="label">Name</div>
                    <div slot="text">{{getName()}}</div>
                </vFormItemH>
                <vFormItemH>
                    <div slot="label">Email</div>
                    <div slot="text">{{user.email}}</div>
                </vFormItemH>
                <vFormItemH>
                    <div slot="label">Birthday</div>
                    <div slot="text">{{user.detail && user.detail.birthday ? user.detail.birthday : ''}}</div>
                </vFormItemH>
                <vFormItemH>
                    <div slot="label">Phone</div>
                    <div slot="text">{{user.phone}}</div>
                </vFormItemH>
            </div>
            <div class="btn-wrapper">
                <vButton class="btn" text="Edit" @btnClick="handleEdit" />
            </div>
        </div>
        <div slot="body-footer">
            <div class="footer"></div>
        </div>
    </vFormPage>
</template>
<style scoped>
    /* 顶部样式 */
    .header .title {
        padding-left: 36px;
        padding-top: 35px;
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: left;
        color: #fff;
    }

    .header .close {
        position: absolute;
        text-align: -webkit-right;
        color: #CFFFF9;
        font-size: 24px;
        width: 365px;
        padding-top: 35px;
    }

    /* 底部样式 */
    .btn-wrapper {
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
    }

    .btn-wrapper .btn {
        width: 166px;
    }

    .footer {
        height: 88px;
    }


    /* 表单样式 */
    .form-title-wrapper {
        text-align: center;
    }

    .form-title-wrapper img {
        width: 83px;
        height: 83px;
        margin-top: 75.3px;
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import { ME_ACTIVE_SRC } from '../../consts/const.js';
    import { getUserName } from '../../kits/tools.js';
    import vFormPage from '../../components/Formpage.vue';
    import vButton from '../../components/Button.vue';
    import vFormItemH from '../../components/FormItemHorizon.vue';


    export default {
        name: 'v-profile-view',
        components: { vFormPage, vButton, vFormItemH },
        data() {
            return {
                ME_ACTIVE_SRC
            }
        },
        created() {
        },
        mounted() {
        },
        computed: {
            ...mapGetters({
                user: 'app/getUser',
            })
        },
        methods: {
            getName(){
                return getUserName(this.user);
            },
            handleClose() {
                this.$router.replace('/profile');
            },
            handleEdit() {
                this.$router.replace('/profile/edit');
            }
        }
    };
</script>